<template>
  <div
    class="container mx-auto px-4 lg:px-24 py-24 grid gap-0 space-y-8 md:space-y-0"
  >
    <div
      v-if="showLoading === false"
      class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1"
    >
      <h4 class="text-black font-libre dark:text-white transition-colors">
        {{ $t('OUR_BLOG_ENTRIES') }}
      </h4>
      <div class="grid gap-5 lg:gap-10 grid-cols-1 lg:grid-cols-2 justify-items-stretch mt-8 lg:mt-12">
        <a
          v-for="entry in items"
          :key="entry.slug"
          data-aos="fade-zoom-in"
          :href="'https://storysh.de/blog/' + entry.slug"
          class="group w-full flex items-center"
        >
          <div
            class="transition-all lazy mr-6 shadow-md group-hover:shadow-2xl w-20 h-20 rounded-full background"
            style="flex: 0 0 auto;"
            :data-bg="getViewportImage(entry.images[0])"
            :data-bg-hidpi="getViewportImage(entry.images[0])"
          />
          <div class="flex-shrink">
            <h6 class="text-black dark:text-white line-clamp-1 font-sans group-hover:text-primary transition-colors">{{ entry.title }}</h6>
            <p class="text-gray-700 dark:text-gray-300 font-medium text-sm flex items-center">
              <span
                v-html="getTeaser(entry.teaser)"
              />
            </p>
          </div>
        </a>
      </div>
    </div>
    <div
      v-if="showLoading === true"
      class="col-span-1 flex flex-wrap pb-10 lg:pb-0"
    >
      <div
        v-for="(n,index) in itemLimit"
        :key="index"
        class="w-full relative p-3 post-loop-wrapper"
      >
        <loading-tile
          :class="'h-56'"
          :wrapperclass="'w-full'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
const LoadingTile = defineAsyncComponent(() =>
  import("../../../../../../FrontendBundle/assets/js/components/common/Content/LoadingTile" /* webpackChunkName: "loading-tile" */)
);

export default {
  name: "BlogListPreview",
  components: {
    LoadingTile
  },
  props: {},
  data() {
    return {
      items: [],
      showLoading: true
    };
  },
  created() {
    this.retrieveItems();
  },
  methods: {
    getViewportImage(image) {
      if (this.deviceWidth > 480) {
        return this.getImageSrc(image, 'desktop');
      } else {
        return this.getImageSrc(image, 'mobile');
      }
    },
    getImageSrc(image, viewport) {
      if (typeof image === 'string' || image instanceof String) {
        return image;
      }
      if (image.media) {
        if (typeof image.media.thumbnails[viewport] === 'undefined') {
          return image.media.src;
        } else {
          return image.media.thumbnails[viewport]['list'].img;
        }
      }
    },
    getTeaser(teaser) {
      return this.truncate(teaser, 144, '</p>');
    },
    truncate: function (text, length, suffix) {
      if (typeof text === 'undefined') {
        return '';
      }
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    retrieveItems() {
      axios.get(process.env.apiURL + '/fe/api/blog/list/preview')
        .then((response) => {
          this.items = response.data;
          this.showLoading = false;
          setTimeout(() => {
            window.LL.update();
          }, "100");
        })
        .catch((e) => {
        });
    },
  }
}
</script>